<template>
  <div class="row">
    <div class="col-auto">
      <label for="Location">Your location:</label>
      <a id="Location" class="popup-link" @click="openPopup">{{
        locationDisplay
      }}</a>
    </div>
    |
    <div class="col-auto">
      <label for="Seating">Seats:</label>
      <a id="Seating" class="popup-link" @click="openPopup"
        >at least {{ searchOptions.seatingCapacity }}</a
      >
    </div>
    |
    <div class="col-auto">
      <label for="Pricing">Pricing:</label>
      <a class="popup-link" @click="openPopup">{{ priceDisplay }}</a>
    </div>
    |
    <div class="col-auto">
      <label for="Range">Range:</label>
      <a id="Range" class="popup-link" @click="openPopup"
        >{{ searchOptions.dailyDistance }} miles +</a
      >
    </div>
    |
    <div class="col-auto">
      <label for="BodyStyles">Body:</label>
      <a id="BodyStyles" class="popup-link" @click="openPopup"
        >{{ bodyStyleText }}</a
      >
    </div>
    <div
      id="SearchPopup"
      class="container-fluid"
      v-show="showPopup"
      :style="{ top: popupPosition.y, left: popupPosition.x }"
    >
      <div class="row">
        <div class="col-12 text-right">
          <a href="" @click.prevent="closePopup">&times;</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <search-filters @update:search="optionsChanged" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import common from "Mixins/common";
const SearchFilters = () => import("Components/SearchFilters");
export default {
  props: {},
  data() {
    return {
      showPopup: false,
      popupPosition: { x: "0px", y: "0px" },
    };
  },
  methods: {
    openPopup($event) {
      const location = $event.target.getBoundingClientRect()
      this.popupPosition.x = `${location.left}px`;
      this.popupPosition.y = `${location.bottom}px`;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    optionsChanged() {
      this.showPopup = false;
    },
  },
  computed: {
    locationDisplay() {
      if (this.$store.state.locationInfo?.address) {
        return `${this.$store.state.locationInfo.address.city}, ${this.$store.state.locationInfo.address.state}`;
      } else {
        return "choose";
      }
    },
    priceDisplay() {
      const priceLow = common
        .toCurrency(this.searchOptions.priceLow)
        .split(",")[0];
      const priceHigh = common
        .toCurrency(this.searchOptions.priceHigh)
        .split(",")[0];
      return `${priceLow}-${priceHigh}k`;
    },
    searchOptions() {
      return this.$store.state.searchOptions;
    },
    bodyStyleText() {
      if(this.searchOptions.bodyStyles.length === 0){
        return "All";
      }
      else if(this.searchOptions.bodyStyles.length === 1){
        return this.searchOptions.bodyStyles[0];
      }
      else{
        return `${this.searchOptions.bodyStyles.length} selected`;
      }
    }
  },
  mixins: [common],
  components: { SearchFilters },
};
</script>
<style lang="scss" scoped>
div {
  color: white;
}

label {
  color: white;
  padding-right: 5px;
}

.popup-link {
  color: #3b9bf5;
  text-decoration: underline;
}

.popup-link:hover {
  cursor: pointer;
}

#SearchPopup {
  color: #3b9bf5;
  height: 210px;
  width: 350px;
  position: absolute;
  z-index: 999;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}
</style>
